import React from "react";
import "./Doer.scss";
import { DoerContent } from "./DoerContent/DoerContent";
import { useGetDoerDetails } from "../../../../services/doer";
import { useParams } from "react-router";
import { Spin } from "antd";

interface IDoerComponent {
  doerId?: string;
}

export const Doer: React.FC<IDoerComponent> = ({ doerId }) => {
  const { id } = useParams();
  const { doer, isLoading, refetch } = useGetDoerDetails(doerId || id);

  return (
    <div className={"doer-container"}>
      <div className={`doer-page-wrapper ${isLoading ? "loading" : ""}`}>
        {isLoading ? (
          <Spin />
        ) : doer ? (
          <DoerContent doer={doer} refetch={refetch} />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};
