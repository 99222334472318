import React, { useState } from "react";
import "./Task.scss";
import { useNavigate, useParams } from "react-router";
import {
  getStatusColor,
  getStatusName,
  unixDurationToTime,
  unixToMoment,
} from "../../../../utils/helpers";
import {
  ArrowRightOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  CreditCardOutlined,
  DollarCircleOutlined,
  EnvironmentOutlined,
  PhoneOutlined,
  SwapOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  AddCommentModal,
  ContainerWithEdit,
  DoerItem,
  InternalComment,
  SectionTitle,
} from "../../../../components";
import {
  ITaskDetails,
  updateTask,
  useGetTaskDetails,
} from "../../../../services/task";
import { EditStatusModal } from "../ActionModals/EditStatusModal/EditStatusModal";
import { useMutation } from "react-query";
import { message } from "antd";
import { EditTitleModal } from "../ActionModals/EditTitleModal/EditTitleModal";
import { EditDetailsModal } from "../ActionModals/EditDetailsModal/EditDetailsModal";
import { EditDescriptionModal } from "../ActionModals/EditDescriptionModal/EditDescriptionModal";
import { EditDoerModal } from "../ActionModals/EditDoerModal/EditDoerModal";
import {
  ICancellationReason,
  useGetCancellationReasons,
} from "../../../../services/catalog";
import moment from "moment";

interface ITaskComponent {
  actionButtons?: JSX.Element;
  task: ITaskDetails;
}

export const Task: React.FC<ITaskComponent> = ({ actionButtons, task }) => {
  const { id } = useParams();
  const { refetch } = useGetTaskDetails(id);
  const { cancellationReasons } = useGetCancellationReasons(true);
  const [commentModalOpen, setCommentModalOpen] = useState<boolean>(false);
  const navigate = useNavigate();

  const [editStatusModalOpen, setEditStatusModalOpen] =
    useState<boolean>(false);
  const [editTitleModalOpen, setEditTitleModalOpen] = useState<boolean>(false);
  const [editDetailsModalOpen, setEditDetailsModalOpen] =
    useState<boolean>(false);
  const [editDescriptionModalOpen, setEditDescriptionModalOpen] =
    useState<boolean>(false);
  const [editDoerModalOpen, setEditDoerModalOpen] = useState<boolean>(false);

  const { isLoading: updateTaskLoading, mutate: mutateUpdateTask } =
    useMutation(updateTask, {
      onError: (err) => {
        message.error(err);
      },
    });

  const formatPhoneNumber = (phoneNumber: string) => {
    if (phoneNumber.startsWith("995")) {
      return `+${phoneNumber}`;
    }
    return phoneNumber;
  };

  const getCancellationReason = (cancellationReason: ICancellationReason) => {
    const reason = cancellationReasons?.find(
      (item) => item.id === cancellationReason?.id,
    );
    return reason?.name ?? "N/A";
  };

  const handleSubmit = (values: any, afterSubmit?: () => void) => {
    mutateUpdateTask(
      { taskId: id, ...values },
      {
        onSuccess: () => {
          afterSubmit?.();
          refetch().catch((err) => message.error(err));
        },
      },
    );
  };

  return (
    <div className={"task-details-wrapper"}>
      <div className={"task-info-wrapper"}>
        <div className={"task-main-info-wrapper"}>
          <div className={"task-info-details-wrapper"}>
            <ContainerWithEdit onEditClick={() => setEditStatusModalOpen(true)}>
              <div className={"client-name-wrapper"}>
                <div className={"client-name"}>{task?.clientName}</div>
                <SwapOutlined
                  className="swap-icon"
                  style={{ color: getStatusColor(task?.taskStatus) }}
                />
                <div style={{ color: getStatusColor(task?.taskStatus) }}>
                  {getStatusName(task?.taskStatus)}
                </div>
              </div>
            </ContainerWithEdit>
            {task?.title && (
              <ContainerWithEdit
                onEditClick={() => setEditTitleModalOpen(true)}
              >
                <div className={"task-info-title"}>{task.title}</div>
              </ContainerWithEdit>
            )}
            {/*<div className={"task-info-detail"}>*/}
            {/*  <PhoneOutlined />*/}
            {/*  <div>{task.client.phoneNumber}</div>*/}
            {/*</div>*/}
            {/*<div className={"task-details-wrapper with-edit"}>*/}
            {task.cancellationReason && (
              <div className={"cancellation-reason-wrapper"}>
                <div className={"cancellation-reason-text"}>
                  გაუქმების მიზეზი:
                </div>
                <div className={"cancellation-reason"}>
                  {getCancellationReason(task.cancellationReason)}
                </div>
              </div>
            )}
            <ContainerWithEdit
              onEditClick={() => setEditDetailsModalOpen(true)}
              align={"flex-start"}
            >
              <div className={"task-details"}>
                {task?.clientId && (
                  <div className={"task-info-detail"}>
                    <UserOutlined />
                    <div>კლიენტის ID:</div>
                    <div>{task.clientId}</div>
                  </div>
                )}
                {task?.createdTimestamp && (
                  <div className={"task-info-detail"}>
                    <ClockCircleOutlined />
                    <div>შექმნის თარიღი (დღე/თვე/წელი):</div>
                    <div>
                      {moment(task.createdTimestamp).format("DD/MM/YYYY")}
                    </div>
                  </div>
                )}
                {(task?.city || task?.address) && (
                  <div className={"task-info-detail"}>
                    <EnvironmentOutlined />
                    <div>მისამართი:</div>
                    {task?.city?.name && (
                      <div>{`${task.city?.name}${
                        task?.address ? "," : ""
                      }`}</div>
                    )}
                    {task?.address && <div>{task.address}</div>}
                  </div>
                )}
                {task?.price && (
                  <div className={"task-info-detail"}>
                    <DollarCircleOutlined />
                    <div>ფასი:</div>
                    <div>{task.price}</div>
                  </div>
                )}
                {task?.phoneNumber && (
                  <div className={"task-info-detail"}>
                    <PhoneOutlined />
                    <div>ნომერი:</div>
                    <div>{formatPhoneNumber(task.phoneNumber)}</div>
                  </div>
                )}
                {task?.proposedStartTime && (
                  <div className={"task-info-detail"}>
                    <ClockCircleOutlined />
                    <div>დაწყების შეთანხმებული დრო:</div>
                    <div>{unixToMoment(task.proposedStartTime)}</div>
                  </div>
                )}
                {task?.proposedDuration && (
                  <div className={"task-info-detail"}>
                    <ClockCircleOutlined />
                    <div>შეთანხმებული ხანგრძლივობა:</div>
                    <div>{unixDurationToTime(task.proposedDuration)}</div>
                  </div>
                )}
                {task?.actualStartTime && (
                  <div className={"task-info-detail"}>
                    <ClockCircleOutlined />
                    <div>დაწყების რეალური დრო:</div>
                    <div>{unixToMoment(task.actualStartTime)}</div>
                  </div>
                )}
                {task?.transactionId && (
                  <div className={"task-info-detail"}>
                    <CreditCardOutlined />
                    <div>ტრანზაქციის ID:</div>
                    <div>{task.transactionId}</div>
                  </div>
                )}
                {task?.transactionUrl && (
                  <div className={"task-info-detail"}>
                    <CreditCardOutlined />
                    <div>ტრანზაქციის ლინკი:</div>
                    <a
                      href={task.transactionUrl}
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      {task.transactionUrl}
                    </a>
                  </div>
                )}
                <div className={"task-info-detail"}>
                  <ArrowRightOutlined />
                  <div>პირდაპირი გადარიცხვა:</div>
                  <div>{task.isDirectTransfer ? "კი" : "არა"}</div>
                </div>
                {task?.cancellationReason && (
                  <div className={"task-info-detail"}>
                    <CloseCircleOutlined />
                    <div>გაუქმების მიზეზი:</div>
                    <div>{task.cancellationReason?.name}</div>
                  </div>
                )}
              </div>
            </ContainerWithEdit>
          </div>
        </div>
        {task?.taskStatus?.toString() !== "5" && ( //status !== done
          <div className={"task-action-buttons-wrapper"}>{actionButtons}</div>
        )}
      </div>
      {task?.doer && (
        <div className={"task-assignee-wrapper"}>
          <ContainerWithEdit
            className={"section-title-with-edit"}
            onEditClick={() => setEditDoerModalOpen(true)}
          >
            <SectionTitle title={"შემსრულებელი"} />
          </ContainerWithEdit>
          <DoerItem
            doer={task.doer}
            onDoerClick={(doerId) => navigate(`/doers/${doerId}`)}
          />
        </div>
      )}
      <div className={"task-description-wrapper"}>
        <ContainerWithEdit
          className={"section-title-with-edit"}
          onEditClick={() => setEditDescriptionModalOpen(true)}
        >
          <SectionTitle title={"დავალების აღწერა"} />
        </ContainerWithEdit>
        <div className={"task-description"}>{task.description}</div>
        <div className={"task-images-wrapper"}>
          {task.images && task.images.length ? (
            task.images.map((item) => {
              return (
                <div key={item} className={"task-image-wrapper"}>
                  <img className={"task-image"} src={item} alt={"task image"} />
                </div>
              );
            })
          ) : (
            <></>
          )}
        </div>
      </div>
      <div>
        {task.internalComment ? (
          <ContainerWithEdit
            className={"section-title-with-edit"}
            onEditClick={() => setCommentModalOpen(true)}
          >
            <SectionTitle
              title={"შიდა კომენტარი"}
              withAddIcon={false}
              iconColor={getStatusColor(task.taskStatus)}
              onAddClick={() => setCommentModalOpen(true)}
            />
          </ContainerWithEdit>
        ) : (
          <SectionTitle
            title={"შიდა კომენტარი"}
            withAddIcon
            iconColor={getStatusColor(task.taskStatus)}
            onAddClick={() => setCommentModalOpen(true)}
          />
        )}
        <AddCommentModal
          modalOpen={commentModalOpen}
          setModalOpen={setCommentModalOpen}
          color={getStatusColor(task.taskStatus)}
          currentComment={task.internalComment}
          loading={updateTaskLoading}
          onSubmit={handleSubmit}
        />
        {task?.internalComment && (
          <div className={"internal-comments-wrapper"}>
            <InternalComment comment={task?.internalComment} />
          </div>
        )}
      </div>
      <EditStatusModal
        modalOpen={editStatusModalOpen}
        setModalOpen={setEditStatusModalOpen}
        selectedStatus={task.taskStatus}
        onSubmit={handleSubmit}
        loading={updateTaskLoading}
      />
      <EditTitleModal
        modalOpen={editTitleModalOpen}
        setModalOpen={setEditTitleModalOpen}
        currentTitle={task.title}
        onSubmit={handleSubmit}
        loading={updateTaskLoading}
      />
      <EditDetailsModal
        modalOpen={editDetailsModalOpen}
        setModalOpen={setEditDetailsModalOpen}
        initialValues={{
          cityId: task.city?.id ? +task.city.id : undefined,
          address: task.address,
          price: task.price,
          proposedStartTime: task.proposedStartTime,
          proposedDuration: task.proposedDuration,
          transactionId: task.transactionId,
          transactionUrl: task.transactionUrl,
        }}
        onSubmit={handleSubmit}
        loading={updateTaskLoading}
      />
      <EditDescriptionModal
        modalOpen={editDescriptionModalOpen}
        setModalOpen={setEditDescriptionModalOpen}
        onSubmit={handleSubmit}
        loading={updateTaskLoading}
        currentDescription={task.description}
      />
      <EditDoerModal
        modalOpen={editDoerModalOpen}
        setModalOpen={setEditDoerModalOpen}
        onSubmit={handleSubmit}
        loading={updateTaskLoading}
        currentDoer={task.doer}
      />
    </div>
  );
};
