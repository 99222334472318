import React from "react";
import "antd/dist/reset.css";
import "./App.scss";
// import "antd/dist/antd.min.css";
// import "antd/dist/antd.css";
import { Auth } from "./modules";
import { Navigate, Route, Routes } from "react-router-dom";
import { Chat, Doers, Tasks } from "./modules/Content";
import { Doer } from "./modules/Content/Doers";
import { ProtectedRoute } from "./router/ProtectedRoute/ProtectedRoute";
import { Layout } from "./modules/Layout/Layout";
import { TaskDetails } from "./modules/Content/Tasks";
import { Reviews } from "./modules/Content/Reviews";

function App() {
  return (
    <Routes>
      <Route
        element={
          <ProtectedRoute>
            <Layout />
          </ProtectedRoute>
        }
      >
        <Route path="/" element={<Tasks />} />
        <Route path="/tasks" element={<Tasks />} />
        <Route path="/tasks/:status" element={<Tasks />} />
        <Route path="/tasks/:status/:id" element={<TaskDetails />} />
        <Route path="/doers" element={<Doers withAddDoer />} />
        <Route path="/doers/:id" element={<Doer />} />
        <Route path="/reviews" element={<Reviews />} />
        <Route path="/chat" element={<Chat />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Route>
      <Route path="/auth" element={<Auth />} />
    </Routes>
  );
}

export default App;
