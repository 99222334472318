import React, { useState } from "react";
import { Task } from "../Task/Task";
import { Button } from "antd";
import { RejectTaskModal } from "../ActionModals/RejectTaskModal/RejectTaskModal";
import { FindDoerModal } from "../ActionModals";
import { ITaskDetails } from "../../../../services/task";

interface ICreatedTaskWrapper {
  task: ITaskDetails;
  refetchTask: any;
}

export const CreatedTaskWrapper: React.FC<ICreatedTaskWrapper> = ({
  task,
  refetchTask,
}) => {
  const [rejectModalOpen, setRejectModalOpen] = useState<boolean>(false);
  const [findDoerModalOpen, setFindDoerModalOpen] = useState<boolean>(false);

  return (
    <Task
      task={task}
      actionButtons={
        <>
          <Button
            className={"action-button red"}
            onClick={() => setRejectModalOpen(true)}
          >
            {"დავალების უარყოფა"}
          </Button>
          <RejectTaskModal
            modalOpen={rejectModalOpen}
            setModalOpen={setRejectModalOpen}
            title={"დავალების უარყოფა"}
            confirmationText={"ნამდვილად გსურთ დავალების უარყოფა?"}
            task={task}
          />
          <Button
            className={"action-button blue"}
            onClick={() => setFindDoerModalOpen(true)}
          >
            {"შემსრულებლის მოძებნა"}
          </Button>
          <FindDoerModal
            refetchTaskDetails={refetchTask}
            taskId={task.id}
            modalOpen={findDoerModalOpen}
            setModalOpen={setFindDoerModalOpen}
          />
        </>
      }
    />
  );
};
