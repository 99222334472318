import React, { useEffect, useMemo, useState } from "react";
import "./FindDoerModal.scss";
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  message,
  Modal,
  Row,
  TimePicker,
} from "antd";
import { Doer, Doers } from "../../../Doers";
import { useGetSkills } from "../../../../../services/catalog";
import { formValidation } from "../../../../../utils/formValidation";
import { useMutation } from "react-query";
import { assignTask } from "../../../../../services/task";
import { CustomSelect } from "../../../../../components/CustomSelect/CustomSelect";
import { splitShares } from "../../../../../services/generalTypes";

interface IFindDoerModal {
  modalOpen: boolean;
  //eslint-disable-next-line
  setModalOpen: any;
  taskId?: string;
  refetchTaskDetails: any;
}

export const FindDoerModal: React.FC<IFindDoerModal> = ({
  modalOpen,
  setModalOpen,
  taskId,
  refetchTaskDetails,
}) => {
  const [mode, setMode] = useState<"skills" | "doers" | "doer" | "details">(
    "skills",
  );
  const [selectedSkills, setSelectedSkills] = useState<Array<number | string>>(
    [],
  );
  const [doerId, setDoerId] = useState<string>();
  const [form] = Form.useForm();

  const { skills } = useGetSkills();
  const { isLoading: assignTaskLoading, mutate: mutateAssignTask } =
    useMutation(assignTask, {
      onSuccess: () => {
        setModalOpen(false);
        refetchTaskDetails().catch((err: any) => {
          message.error(err);
        });
      },
      onError: () => {
        message.error("დაფიქსირდა შეცდომა");
      },
    });

  useEffect(() => {
    if (!modalOpen) {
      setDoerId(undefined);
      setMode("skills");
      form.resetFields();
    }
  }, [modalOpen]);

  useEffect(() => {
    if (doerId) {
      setMode("doer");
    }
  }, [doerId]);

  const days = useMemo(() => {
    const arr = [];
    for (let i = 0; i <= 10; i++) {
      arr.push({ id: i, name: i.toString() });
    }
    return arr;
  }, []);

  const hours = useMemo(() => {
    const arr = [];
    for (let i = 0; i < 24; i++) {
      arr.push({ id: i, name: i.toString() });
    }
    return arr;
  }, []);

  const minutes = useMemo(() => {
    const arr = [];
    for (let i = 0; i < 60; i++) {
      if (i % 15 === 0) {
        arr.push({ id: i, name: i.toString() });
      }
    }
    return arr;
  }, []);

  const handleAssignUser = (formValues: any) => {
    //1 day - 3600 * 24 unix
    //1 hour - 3600 unix
    //1 minute - 60 unix
    if (taskId && doerId) {
      mutateAssignTask({
        taskId: taskId,
        doerId: doerId,
        price: formValues.price,
        duration:
          formValues.days * 3600 * 24 +
          formValues.hours * 3600 +
          formValues.minutes * 60,
        proposedStartTime: formValues.proposedStartTime.unix(),
        splitShare: formValues.splitShare,
      });
    }
  };

  const renderModalContent = () => {
    switch (mode) {
      case "details":
        return (
          <Form
            form={form}
            className={"find-doer-details"}
            onFinish={handleAssignUser}
          >
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item name={"price"} rules={formValidation.required}>
                  <Input
                    placeholder={"ფასი"}
                    className={"doer-details-input"}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={8}>
                <Form.Item name={"days"} rules={formValidation.required}>
                  <CustomSelect
                    className={"doer-details-input"}
                    options={days}
                    placeholder={"დღე"}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name={"hours"} rules={formValidation.required}>
                  <CustomSelect
                    className={"doer-details-input"}
                    options={hours}
                    placeholder={"საათი"}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name={"minutes"} rules={formValidation.required}>
                  <CustomSelect
                    className={"doer-details-input"}
                    options={minutes}
                    placeholder={"წუთი"}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name={"proposedStartTime"}
                  rules={formValidation.required}
                >
                  <DatePicker
                    className={"doer-details-input"}
                    placeholder={"დაწყების დრო"}
                    // onChange={(date) => console.log("date", date, moment(date).unix())}
                    showTime={{
                      format: "HH:mm",
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name={"splitShare"} rules={formValidation.required}>
                  <CustomSelect
                    options={splitShares}
                    placeholder={"საკომისიო"}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        );
      case "doer":
        return (
          <div>
            <Doer doerId={doerId} />
          </div>
        );
      case "doers":
        return (
          <div>
            <Doers
              pageSize={3}
              onDoerClick={(id) => {
                setDoerId(id);
              }}
              defaultSelectedSkills={selectedSkills}
            />
          </div>
        );
      default:
        return (
          <Checkbox.Group
            className={"find-doer-checkbox-group"}
            options={
              skills?.map((item) => ({ label: item.name, value: item.id })) ||
              []
            }
            onChange={(selected: Array<any>) => setSelectedSkills(selected)}
          />
        );
    }
  };

  const renderFooterContent = () => {
    switch (mode) {
      case "details":
        return (
          <>
            <Button onClick={() => setMode("doer")}>უკან</Button>
            <Button
              loading={assignTaskLoading}
              onClick={form.submit}
              style={{
                backgroundColor: "#37A6F7",
                borderColor: "#37A6F7",
                color: "#FFF",
              }}
            >
              შემსრულებლის არჩევა
            </Button>
          </>
        );
      case "doer":
        return (
          <>
            <Button onClick={() => setMode("doers")}>უკან</Button>
            <Button onClick={() => setMode("details")}>შემდეგი</Button>
          </>
        );
      case "doers":
        return <Button onClick={() => setMode("skills")}>უკან</Button>;
      default:
        return (
          <Button
            disabled={!selectedSkills.length}
            onClick={() => setMode("doers")}
          >
            შემდეგი
          </Button>
        );
    }
  };

  return (
    <Modal
      // width={ mode === "doer" || mode === "doers"  ? 700 : undefined}
      width={700}
      open={modalOpen}
      className={"find-doer-modal"}
      title={"შემსრულებლის მოძებნა"}
      footer={renderFooterContent()}
      onCancel={() => setModalOpen(false)}
      maskClosable
      destroyOnClose
    >
      <div className={"find-modal-content-wrapper"}>{renderModalContent()}</div>
    </Modal>
  );
};
