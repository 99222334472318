import React from "react";
import "./DoerItem.scss";
import { IDoer } from "../../services/doer";
import EmptyAvatar from "../../assets/png/EmptyAvatar.png";
import EmptyCompany from "../../assets/png/EmptyCompany.png";
import { ITaskDoer } from "../../services/task";
import { CheckCircleOutlined } from "@ant-design/icons";
import { onImageError } from "../../utils/helpers";

interface IDoerItem {
  doer: IDoer | ITaskDoer;
  onDoerClick?: (id: string, doer: IDoer | ITaskDoer) => void;
}

export const DoerItem: React.FC<IDoerItem> = ({ doer, onDoerClick }) => {
  const isCompany = doer.doerType === 2;

  const getDoerAvatarSrc = () => {
    if (isCompany) {
      return doer.companyImageUrl || EmptyCompany;
    }
    return doer.avatar || EmptyAvatar;
  };
  return (
    <div
      className={"doer-list-item"}
      onClick={() => onDoerClick?.(doer.id, doer)}
    >
      <div className={"list-item-left-wrapper"}>
        <img
          className="doer-image"
          src={getDoerAvatarSrc()}
          onError={onImageError}
          alt={""}
        />
        <div>
          <div className={"doer-name-wrapper"}>
            <div className={"doer-name"}>
              {isCompany
                ? doer.companyName
                : `${doer.firstName} ${doer.lastName}`}
            </div>
            {doer?.isTrusted && (
              <CheckCircleOutlined className={"doer-verified-icon"} />
            )}
            {"city" in doer && doer?.city && (
              <div>{` - ${doer.city.name}`}</div>
            )}
          </div>
          {"skills" in doer && doer.skills && (
            <div className={"doer-categories"}>
              {doer.skills.map((skill) => skill.name).join(", ")}
            </div>
          )}
          {"aboutMe" in doer && doer.aboutMe && (
            <div className={"doer-categories"}>{doer.aboutMe}</div>
          )}
        </div>
      </div>
      <div className={"list-item-right-wrapper"}>
        {"avaibility" in doer && doer?.avaibility && (
          <div className={"doer-available-days"}>{doer.avaibility}</div>
        )}
        <div className={"doer-phone-number"}>{`+${doer.phoneNumber}`}</div>
      </div>
    </div>
  );
};
