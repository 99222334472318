import { useQuery } from "react-query";
import { apiClient } from "./apiClient";
import { GeneralResponseType, IOption } from "./generalTypes";

type GetCitiesResp = GeneralResponseType<IOption[]>;

export const getCities = (): Promise<GetCitiesResp> =>
  apiClient.get("/catalog/cities").then((resp) => resp.data);

export const useGetCities = () => {
  const { data, isLoading } = useQuery(["catalog-cities"], () => getCities());
  return {
    isLoading,
    cities: data?.data,
    status: data?.status,
  };
};

export interface ICancellationReason {
  id: number;
  name: string;
  isTerminating: boolean;
}

type GetCancellationReasonsResp = GeneralResponseType<ICancellationReason[]>;

export const getCancellationReasons = (): Promise<GetCancellationReasonsResp> =>
  apiClient.get("/catalog/cancellationReasons").then((resp) => resp.data);

export const useGetCancellationReasons = (enabled = true) => {
  const { data, isLoading, refetch, isRefetching } = useQuery({
    queryKey: ["catalog-cancellationReasons"],
    queryFn: () => getCancellationReasons(),
    enabled,
  });
  return {
    isLoading,
    cancellationReasons: data?.data,
    status: data?.status,
    refetch,
    isRefetching,
  };
};

type GetSkillsResp = GeneralResponseType<IOption[]>;

export const getSkills = (): Promise<GetSkillsResp> =>
  apiClient.get("/catalog/skills").then((resp) => resp.data);

export const useGetSkills = () => {
  const { data, isLoading, refetch, isRefetching } = useQuery({
    queryKey: ["catalog-skills"],
    queryFn: () => getSkills(),
  });
  return {
    isLoading,
    skills: data?.data,
    status: data?.status,
    refetch,
    isRefetching,
  };
};
