import React, { useEffect, useState } from "react";
import "./SideBar.scss";
import { Menu } from "antd";
import Sider from "antd/es/layout/Sider";
import { Logo } from "../../../assets/svg";
import {
  MessageOutlined,
  ScheduleOutlined,
  StarOutlined,
  TeamOutlined,
} from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router";

export const SideBar = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [selectedKeys, setSelectedKeys] = useState(["1"]);

  useEffect(() => {
    if (pathname.includes("/tasks")) {
      setSelectedKeys(["1"]);
    } else if (pathname.includes("/doers")) {
      setSelectedKeys(["2"]);
    } else if (pathname.includes("/reviews")) {
      setSelectedKeys(["3"]);
    } else if (pathname.includes("/chat")) {
      setSelectedKeys(["4"]);
    }
  }, [pathname]);

  const onMenuItemClick = (pathName?: string) => {
    navigate(`/${pathName}`);
  };
  return (
    <Sider collapsible className={"sidebar"} breakpoint={"md"}>
      <div className={"logo-container"}>
        <Logo />
      </div>
      <Menu
        className={"sidebar-menu"}
        theme="dark"
        mode="inline"
        defaultSelectedKeys={["1"]}
        selectedKeys={selectedKeys}
        items={[
          {
            key: "1",
            icon: <ScheduleOutlined />,
            label: "დავალებები",
            onClick: () => onMenuItemClick("tasks"),
          },
          {
            key: "2",
            icon: <TeamOutlined />,
            label: "შემსრულებლები",
            onClick: () => onMenuItemClick("doers"),
          },
          {
            key: "3",
            icon: <StarOutlined />,
            label: "შეფასებები",
            onClick: () => onMenuItemClick("reviews"),
          },
          {
            key: "4",
            icon: <MessageOutlined />,
            label: "ჩატი",
            onClick: () => onMenuItemClick("chat"),
          },
        ]}
      />
    </Sider>
  );
};
