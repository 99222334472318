export type IOption = {
  id: string | number;
  name: string;
};

type Status = {
  code: number;
  message: string;
};

export type GeneralResponseType<ResponseDataType> = {
  data: ResponseDataType;
  status: Status;
};

export const taskStatusTypes: IOption[] = [
  {
    id: 1,
    name: "created",
  },
  {
    id: 2,
    name: "assigned",
  },
  {
    id: 3,
    name: "accepted",
  },
  {
    id: 4,
    name: "inProgress",
  },
  {
    id: 5,
    name: "done",
  },
  {
    id: 6,
    name: "rejected",
  },
  {
    id: 7,
    name: "refunded",
  },
];

export const taskStatusTypesSelect: IOption[] = [
  {
    id: 1,
    name: "შემოსული",
  },
  {
    id: 2,
    name: "შეთავაზებული",
  },
  {
    id: 3,
    name: "მიღებული",
  },
  {
    id: 4,
    name: "მიმდინარე",
  },
  {
    id: 5,
    name: "დასრულებული",
  },
  {
    id: 6,
    name: "გაუქმებული",
  },
  {
    id: 7,
    name: "უარყოფილი",
  },
  {
    id: 8,
    name: "ანაზღაურებული",
  },
];

export const taskSenderTypes: IOption[] = [
  {
    id: 1,
    name: "ვები",
  },
  {
    id: 2,
    name: "აპლიკაცია",
  },
  {
    id: 3,
    name: "სხვა",
  },
];

export const splitShares: IOption[] = [
  {
    id: 0.03,
    name: "3%",
  },
  {
    id: 0.05,
    name: "5%",
  },
  {
    id: 0.1,
    name: "10%",
  },
  {
    id: 0.15,
    name: "15%",
  },
  {
    id: 0.2,
    name: "20%",
  },
];

export const doerTypes: IOption[] = [
  {
    id: 1,
    name: "კერძო პირი",
  },
  {
    id: 2,
    name: "კომპანია",
  },
  {
    id: 3,
    name: "კერძო პირი კომპანიიდან",
  },
];

export const reviewStatusTypes: IOption[] = [
  {
    id: 1,
    name: "განხილვის პროცესში",
  },
  {
    id: 2,
    name: "უარყოფილი",
  },
  {
    id: 3,
    name: "დადასტურებული",
  },
];
