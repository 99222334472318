import React, { useMemo, useState } from "react";
import "./Tasks.scss";
import { Button, Pagination, Spin } from "antd";
import {
  getStatusName,
  getStatusIcon,
  getStatusColor,
  getStatusId,
} from "../../../utils/helpers";
import { useNavigate, useParams } from "react-router";
import { TaskItem } from "../../../components";
import { PlusOutlined } from "@ant-design/icons";
import { AddTaskModal } from "./ActionModals";
import { useGetTasks } from "../../../services/task";
import { taskStatusTypes } from "../../../services/generalTypes";

export const Tasks = () => {
  const { status: paramsStatus } = useParams();
  const navigate = useNavigate();
  const [addTaskModalOpen, setAddTaskModalOpen] = useState<boolean>(false);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    pageSize: 5,
  });

  const statusesArr = useMemo(() => {
    return taskStatusTypes.map((item) => item.name).slice(0, 5);
  }, [taskStatusTypes]);

  const selectedTab = useMemo(() => {
    return paramsStatus && statusesArr.includes(paramsStatus)
      ? paramsStatus
      : "created";
  }, [paramsStatus, statusesArr]);

  const getTaskStatuses = () => {
    const statusId = getStatusId(selectedTab);
    switch (selectedTab) {
      case "done":
        return [statusId, 6, 7]; //@TODO temporary
      default:
        return [statusId];
    }
  };

  const { tasks, isLoading, refetch, isRefetching } = useGetTasks(
    pagination.currentPage,
    pagination.pageSize,
    getTaskStatuses(),
  );

  const resetPagination = () => {
    setPagination({
      currentPage: 1,
      pageSize: 5,
    });
  };

  return (
    <div className={"tasks-wrapper"}>
      <div className={"statuses-wrapper"}>
        {statusesArr.map((item, index) => {
          const itemId = getStatusId(item);
          return (
            <Button
              key={index}
              style={
                item === selectedTab
                  ? {
                      color: getStatusColor(itemId),
                      borderColor: getStatusColor(itemId),
                    }
                  : {}
              }
              className={"status-item"}
              onClick={() => {
                resetPagination();
                navigate(`/tasks/${item}`);
              }}
            >
              {getStatusIcon(itemId)}
              {getStatusName(itemId)}
            </Button>
          );
        })}
        <Button
          className={"add-new-task-button"}
          onClick={() => setAddTaskModalOpen(true)}
        >
          <PlusOutlined />
        </Button>
        <AddTaskModal
          modalOpen={addTaskModalOpen}
          setModalOpen={setAddTaskModalOpen}
          refetchTasks={refetch}
        />
      </div>
      <div
        className={`tasks-list-wrapper ${
          isLoading || isRefetching ? "loading" : ""
        }`}
      >
        {isLoading || isRefetching ? (
          <Spin />
        ) : (
          tasks?.data?.map((item) => {
            return (
              <TaskItem
                task={item}
                key={item.id}
                onClick={() => navigate(`/tasks/${selectedTab}/${item.id}`)}
                // unisonCode={item?.unisonCode}
                // unisonImage={item?.unisonImage}
              />
            );
          })
        )}
      </div>
      <Pagination
        className={"tasks-list-pagination"}
        current={pagination.currentPage}
        defaultCurrent={1}
        total={tasks?.totalItemCount || 0}
        pageSize={pagination.pageSize}
        pageSizeOptions={[5, 15, 20, 50]}
        onChange={(page) => {
          setPagination((prev) => ({ ...prev, currentPage: page }));
        }}
        onShowSizeChange={(size) => {
          setPagination((prev) => ({ ...prev, pageSize: size }));
        }}
      />
    </div>
  );
};
