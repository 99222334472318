import {
  FileDoneOutlined,
  FileOutlined,
  FileSyncOutlined,
  FileTextOutlined,
  FileUnknownOutlined,
} from "@ant-design/icons";
import React from "react";
import {
  taskStatusTypes,
  taskStatusTypesSelect,
} from "../services/generalTypes";
import moment from "moment";
import ImageErrorFallback from "../assets/png/ImageErrorFallback.png";

export const setToken = (token: string, name = "token") => {
  localStorage.setItem(name, token);
};

export const getToken = (name = "token") => {
  return localStorage.getItem(name);
};

export const removeToken = (name = "token") => {
  return localStorage.removeItem(name);
};

export const unixToMoment = (
  unixDate: number,
  format = "DD MMM YYYY, HH:mm",
) => {
  return moment.unix(unixDate).format(format);
};

export const secondsToDays = (seconds: number) => {
  return Math.floor(seconds / (3600 * 24));
};
export const secondsToHours = (seconds: number) => {
  return Math.floor((seconds % (3600 * 24)) / 3600);
};
export const secondsToMinutes = (seconds: number) => {
  return Math.floor((seconds % 3600) / 60);
};

function secondsToDhms(seconds: number) {
  seconds = Number(seconds);
  const d = Math.floor(seconds / (3600 * 24));
  const h = Math.floor((seconds % (3600 * 24)) / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  const s = Math.floor(seconds % 60);

  const dDisplay = d > 0 ? d + " დღე " : "";
  const hDisplay = h > 0 ? h + " საათი " : "";
  const mDisplay = m > 0 ? m + " წუთი " : "";
  const sDisplay = s > 0 ? s + " წამი " : "";
  return dDisplay + hDisplay + mDisplay + sDisplay;
}

export const unixDurationToTime = (unixDuration: number) => {
  return secondsToDhms(unixDuration);
};

export const getStatusId = (selectedTabName?: string) => {
  return taskStatusTypes.find((item) => item.name === selectedTabName)?.id || 1;
};

export const getStatusIcon = (status: string | number) => {
  switch (status.toString()) {
    case "2":
      return <FileOutlined />;
    case "3":
      return <FileTextOutlined />;
    case "4":
      return <FileSyncOutlined />;
    case "5":
      return <FileDoneOutlined />;
    default:
      return <FileUnknownOutlined />;
  }
};

export const getStatusName = (status: string | number): string => {
  return (
    taskStatusTypesSelect.find(
      (item) => item.id.toString() === status.toString(),
    )?.name || "შემოსული"
  );
};

export const getStatusColor = (status: string | number): string => {
  switch (status.toString()) {
    case "2":
      return "#37A6F7";
    case "3":
      return "#FF8900";
    case "4":
      return "#F9C922";
    case "5":
      return "#06D672";
    default:
      return "#878787";
  }
};

export const getDoerStatus = (status: string | number): string => {
  switch (status.toString()) {
    case "2":
      return "Blocked";
    case "3":
      return "Cancelled";
    case "4":
      return "Disabled";
    case "5":
      return "Deleted";
    default:
      return "Active";
  }
};

export const getDoerStatusColor = (status: string | number): string => {
  switch (status.toString()) {
    case "2":
      return "#FF3100";
    case "3":
      return "#F9C922";
    case "4":
      return "#656565";
    case "5":
      return "#001529";
    default:
      return "#06D672";
  }
};

export const getDoerTypeName = (doerType: string | number): string => {
  switch (doerType.toString()) {
    case "2":
      return "კომპანია";
    case "3":
      return "კერძო პირი კომპანიიდან";
    default:
      return "კერძო პირი";
  }
};

export const onImageError = ({
  currentTarget,
}: {
  currentTarget: EventTarget & HTMLImageElement;
}) => {
  currentTarget.onerror = null; // prevents looping
  currentTarget.src = ImageErrorFallback;
};
