import { apiClient } from "./apiClient";
import { useQuery } from "react-query";
import { GeneralResponseType, IOption } from "./generalTypes";

export interface IDoer {
  id: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  avatar: string;
  avaibility: string;
  city: IOption;
  skills: IOption[];
  isTrusted: boolean;
  doerType: number;
  companyName: string;
  companyImageUrl: string;
}

export interface IDoers {
  data: IDoer[];
  totalItemCount: number;
  page: number;
  offset: number;
  pageCount: number;
}

type GetDoersResp = GeneralResponseType<IDoers>;

export const getDoers = (data: any): Promise<GetDoersResp> => {
  const [_key, { currentPage, pageSize, skillIds, cityId }] = data.queryKey;
  return apiClient
    .post("/doer/filter", {
      pageNumber: currentPage,
      pageSize: pageSize,
      skillIds: skillIds,
      cityId: cityId,
    })
    .then((resp) => resp.data);
};

export const useGetDoers = (
  currentPage: number,
  pageSize: number,
  skillIds: Array<string | number>,
  cityId: number,
  enabledFetch = true,
) => {
  const { data, isLoading, refetch, isRefetching } = useQuery({
    queryKey: ["doer-filter", { currentPage, pageSize, skillIds, cityId }],
    queryFn: getDoers,
    enabled: enabledFetch,
  });
  return {
    isLoading,
    doers: data?.data,
    status: data?.status,
    refetch,
    isRefetching,
  };
};

export interface IReview {
  id: string;
  rating: number;
  comment: string;
  commenterName: string;
  commenterAvatar: string;
}

export interface IDoerDetails {
  id: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  avatar: string;
  skills: IOption[];
  city: IOption;
  aboutMe: string;
  avaibility: string;
  //hourly rate?
  //active tasks?
  //completed tasks?
  iban: string;
  notes: string;
  reviews: IReview[];
  status: number; //?????
  isTrusted: true;
  doerType: number;
  companyName: string;
  companyImageUrl: string;
}

type GetDoerDetailsResp = GeneralResponseType<IDoerDetails>;

export const getDoerDetails = (
  data: any,
): Promise<GetDoerDetailsResp> | null => {
  const [_key, { id }] = data.queryKey;
  if (id) {
    return apiClient.get(`/doer/${id}`).then((resp) => resp.data);
  }
  return null;
};

export const useGetDoerDetails = (id?: number | string) => {
  const { data, isLoading, refetch } = useQuery(
    ["doer-id", { id }],
    getDoerDetails,
  );
  return {
    isLoading,
    doer: data?.data,
    status: data?.status,
    refetch,
  };
};

interface ICreateDoerReq {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  avatar: string;
  cityId: number;
  aboutMe: string;
  availability: string;
  iban: string;
  note: string;
  skillIds: number[];
  isTrusted: true;
  doerType: number;
  companyName: string;
  companyImageUrl: string;
}

export const createDoer = (data: ICreateDoerReq): Promise<string> =>
  apiClient.post("/doer/create", data).then((resp) => resp.data);

export interface IUpdateDoer {
  doerId: string;
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  avatar?: string;
  skills?: number[];
  cityId?: number;
  aboutMe?: string;
  avaibility?: string;
  iban?: string;
  note?: string;
  isTrusted?: true;
  doerType?: number;
  companyName?: string;
  companyImageUrl?: string;
}

export const updateDoer = (data: IUpdateDoer): Promise<void> =>
  apiClient.post("/doer/update", data).then((resp) => resp.data);
