import axios from "axios";

export const API_URL = process.env.REACT_APP_API_URL;
export const getApiUrl = () => `${API_URL}/api/v1`;

export const apiClient = axios.create({
  baseURL: getApiUrl(),
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Accept-Language": "ka-GE",
    "Application-Type": "Web",
    "FCM-Token": "DeviceId",
  },
});
