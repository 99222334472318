import { apiClient } from "./apiClient";
import { getToken, removeToken } from "../utils/helpers";
import { GeneralResponseType } from "./generalTypes";

apiClient.interceptors.request.use(
  (config) => {
    const token = getToken();

    if (token && config?.headers) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    console.log("request error", error);
    return Promise.reject(error);
  },
);

apiClient.interceptors.response.use(
  (resp) => resp,
  (err) => {
    console.log(err);
    if (err.response.status.toString() === "401") {
      removeToken();
    }
    //remove token from localstorage
    //redirect to login
  },
);

// POST https://wempler.ge/api/v1/otp/gosmsOtpRequest
export interface ISendOtpReq {
  phoneNumber: string;
}

export type HashData = {
  hash: string | null;
};

type SendOtpResp = GeneralResponseType<HashData>;

export const sendOtpCode = (data: ISendOtpReq): Promise<SendOtpResp> =>
  apiClient.post("/otp/gosmsOtpRequest", data).then((resp) => {
    return resp.data;
  });

// POST https://wempler.ge/api/v1/auth/gosmsLogin
export interface IOtpVerifyCodeReq {
  phoneNumber: string;
  hash: string;
  code: string;
}

type TokenData = {
  accessToken: string;
  refreshToken: string;
};

type VerifyOtpCodeResp = GeneralResponseType<TokenData>;

export const verifyOtpCode = (
  data: IOtpVerifyCodeReq,
): Promise<VerifyOtpCodeResp> =>
  apiClient.post("/auth/gosmsLogin", data).then((resp) => resp.data);
